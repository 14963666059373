import Handlebars from "handlebars";
import moment from "moment";
import {flattenObject} from "./helper";

function getBrowserLocale() {
    let locale;
    if (navigator.languages != undefined) {
        locale = navigator.languages[0];
    } else {
        locale = navigator.language;
    }


    if (locale === undefined) {
        locale = "de-DE";
    }

    let toks = locale.split("-");
    let [country, language] = toks;

    return {
        locale: locale,
        country: country,
        language: language,
    };
}

export function createHelpers() {
    Handlebars.registerHelper("date", (date, format) => {
        // If format is not given, it's the "context" object of the helper, thus the check for "object"
        if (format === undefined || format == null || typeof (format) === "object") {
            format = "DD.MM.YYYY HH:mm:ss";
        }

        if (date === null || date === undefined) {
            return undefined;
        }
        // TODO: Check moment("not a real date").isValid();

        return moment(date).format(format);
    });

    Handlebars.registerHelper("duration", (duration, format) => {
        // If format is not given, it's the "context" object of the helper, thus the check for "object"
        if (format === undefined || format == null || typeof (format) === "object") {
            format = "HH:mm:ss";
        }

        if (duration === null || duration === undefined) {
            return undefined;
        }

        return moment.duration(duration).format(format);
    });

    Handlebars.registerHelper("durationHumanize", (duration, relative) => {
        // If relative is not given, it's the "context" object of the helper, thus the check for "object"
        if (relative === undefined || relative == null || typeof (relative) === "object") {
            relative = false;
        }

        if (duration === null || duration === undefined) {
            return undefined;
        }

        return moment.duration(duration).humanize(relative);
    });

    Handlebars.registerHelper("durationAs", (duration, format) => {
        // If format is not given, it's the "context" object of the helper, thus the check for "object"
        if (format === undefined || format == null || typeof (format) === "object") {
            format = "minutes";
        }

        if (duration === null || duration === undefined) {
            return undefined;
        }

        return moment.duration(duration).as(format);
    });

    Handlebars.registerHelper("fromNow", (date) => {
        if (date === null || date === undefined) {
            return undefined;
        }

        return moment(date).fromNow();
    });

    Handlebars.registerHelper("replace", function (string, search, replace) {
        if (typeof string === "string") {
            return string?.replace(search, replace);
        }
        return string
    })

    Handlebars.registerHelper("typeof", (value) => {
        return typeof value;
    });

    // Returns a number with a given number of decimals after the decimal separator
    Handlebars.registerHelper("toFixed", (value, decimals) => {
        // If decimals is not given, it's the "context" object of the helper, thus the check for "object"
        if (decimals === undefined || decimals == null || typeof (decimals) === "object") {
            decimals = 2;
        }

        if (value === null || value === undefined) {
            return undefined;
        }

        return Number(value).toFixed(decimals);
    });


    Handlebars.registerHelper("deviceConfig", function (config, name) {
        if (!Array.isArray(config)) {
            return "not an device config collection"
        }
        for (let i = 0; i < config.length; i++) {
            if (config[i]?.name === name) {
                return config[i]?.value
            }
        }
        return "config value " + name + "not found"
    })


    /**
     * An Handlebars helper to format numbers
     *
     * This helper have these three optional parameters:
     *  @var decimalLength int The length of the decimals
     *  @var thousandsSep char The thousands separator
     *  @var decimalSep char The decimals separator
     *
     * Based on:
     *  - mu is too short: http://stackoverflow.com/a/14493552/369867
     *  - VisioN: http://stackoverflow.com/a/14428340/369867
     *
     * Demo: http://jsfiddle.net/DennyLoko/6sR87/
     *
     * Usage: {{numberFormat 1.23 thousandsSep="." decimalSep=","}}
     */
    Handlebars.registerHelper('numberFormat', function (value, options) {
        // Helper parameters
        let locale = getBrowserLocale();
        let dl = options.hash['decimals'] || 2;
        let ts = options.hash['thousandsSep'] || (locale.country === 'de' ? '.' : ',');
        let ds = options.hash['decimalSep'] || (locale.country === 'de' ? ',' : '.');

        // Parse to float
        value = parseFloat(value);

        // The regex
        let re = '\\d(?=(\\d{3})+' + (dl > 0 ? '\\D' : '$') + ')';

        // Formats the number with the decimals
        let num = value.toFixed(Math.max(0, ~~dl));

        // Returns the formatted number
        return (ds ? num.replace('.', ds) : num).replace(new RegExp(re, 'g'), '$&' + ts);
    });

    Handlebars.registerHelper('csv', function (value, options) {
        let locale = getBrowserLocale();
        let sep = options.hash['separator'] || ';';
        let decSep = options.hash['decimalSep'] || (locale.country === 'de' ? ',' : '.');

        if (!_.isArray(value)) {
            return value;
        }

        return value
            .map(e => {
                let str = e ? e.toString() : "";
                if (typeof e === "number") {
                    str = e.toString().replace('.', decSep);
                }
                // Escape quotes
                return str.replace("\"", "\"\"");
            })
            .join(sep);
    });


    Handlebars.registerHelper("ceil", (val) => {
        return Math.ceil(val);
    });
    Handlebars.registerHelper("floor", (val) => {
        return Math.floor(val);
    });

    Handlebars.registerHelper("div", (nom, denom) => {
        return nom / denom;
    });

    Handlebars.registerHelper("mul", (a, b) => {
        return a * b;
    });

    Handlebars.registerHelper("max", (list, property) => {
        if (!(list instanceof Array)) {
            return "";
        }
        return list.reduce((acc, val) => {
            if (property) {
                // Only flatten when needed
                if (property && property.indexOf(".") !== -1) {
                    val = flattenObject(val);
                }
                return Math.max(acc, val[property]);
            } else {
                return Math.max(acc, val);
            }
        }, Number.NEGATIVE_INFINITY);

    });

    Handlebars.registerHelper("not", (v) => {
        return !v;
    });
    Handlebars.registerHelper("eq", (v1, v2) => {
        return v1 == v2;
    });
    Handlebars.registerHelper("lt", (v1, v2) => {
        return v1 < v2;
    });
    Handlebars.registerHelper("lte", (v1, v2) => {
        return v1 <= v2;
    });
    Handlebars.registerHelper("gt", (v1, v2) => {
        return v1 > v2;
    });
    Handlebars.registerHelper("gte", (v1, v2) => {
        return v1 >= v2;
    });

    Handlebars.registerHelper("icon", (name, category) => {
        if (category === undefined || category == null || typeof (category) === "object") {
            category = "utility";
        }

        return `{{icon:${name},${category}}}`;
    });
}